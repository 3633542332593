var config = {
  backendOrigin: window.location.origin + "/",
  tileServerHost: window.location.origin + "/",
};
if (window.location.origin === "http://localhost:3000") {
  config.backendOrigin = "https://localhost:7443/";
  config.tileServerHost = "https://flik-dev.wheregroup.lan/";
}

export default config;
