import React, {useContext} from 'react'
import { DataContext } from '../../context/DataContext';
import { IconButton } from "@mui/material";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function HideFlikDataButton(props) {
  const dataContext = useContext(DataContext);
  return (
      <IconButton
        color="primary"
        variant="contained"
        onClick={() => {
          dataContext.setFlikDataHidden(!dataContext.flikDataHidden)
        }}
        sx={props.sx}
      >
        {dataContext.flikDataHidden ? <VisibilityOffIcon />:<VisibilityIcon />}
      </IconButton>
  )
}