import React from "react";

export default function Imprint() {
  return (<><p><b>Seitenbetreiber i.S.d. § 5 TMG</b><br />
  REDcert Gesellschaft zur Zertifizierung nachhaltig erzeugter Biomasse mbH <br />
  Schwertberger Str. 16<br />
  53177 Bonn<br />
  </p>
  <p>
Telefon: +49 (0) 228 35 06 200<br />
Fax: +49 (0) 228 35 06 280
</p>
  <p>
E-Mail: &#112;&#101;&#116;&#101;&#114;&#046;&#106;&#117;&#101;&#114;&#103;&#101;&#110;&#115;&#064;&#114;&#101;&#100;&#099;&#101;&#114;&#116;&#046;&#100;&#101;<br />
Internet: <a href='www.redcert.org'>www.redcert.org</a>
</p>

<p>
<b>Handelsregister:</b><br />
Registergericht: Amtsgericht Bonn<br />
Registernummer: HRB 17971
</p>

<p>
<b>Geschäftsführer:</b><br />
Peter Jürgens
</p>

<p>
<b>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:</b><br />
DE272307958
</p>
<p>
<b>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:</b><br />
Peter Jürgens<br />
(Anschrift wie oben)
</p>
<p>
<b>Urheberrecht und Bildnachweise:</b><br />
Die Inhalte von flik-suche.de sind - soweit nicht abweichend angegeben -  urheberrechtlich geschützt.
</p></>);
}
