import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import useWindowSize from "../hooks/useWindowSize";
import { AppContext } from "../context/AppContext";
import Imprint from "../pages/Imprint";
import Datasecurity from "../pages/Datasecurity";

export default function Pagedialog(props) {
  const appContext = useContext(AppContext);
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const size = useWindowSize();

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="lg"
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        {props.title}

        <div>
          <a href="http://www.redcert.org" target="blank">
            <img
              src="assets/logo/REDcert.jpg"
              alt="RED cert logo"
              height="35"
              style={{ marginRight: "4px" }}
            />
          </a>
          <a href="https://sure-system.org" target="blank">
            <img src="assets/logo/SURE.jpg" alt="SURE logo" height="35" />
          </a>
        </div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          {props.children}
        </DialogContentText>
      </DialogContent>
      {size.width <= 600 && (
        <>
          <Box
            sx={{
              marginLeft: "10px",
            }}
          >
            <Button>Impressum</Button>
            <Button>Datenschutz</Button>
          </Box>
          <Box
            sx={{
              marginLeft: "10px",
            }}
          >
            <Link to="/">
              <Button onClick={props.handleClose}>Weiter</Button>
            </Link>
          </Box>
        </>
      )}
      {size.width >= 600 && (
        <DialogActions>
          <Box
            sx={{
              position: "absolute",
              left: "5px",
            }}
          ></Box>
          <Link to="/">
            <Button onClick={props.handleClose}>Weiter</Button>
          </Link>
        </DialogActions>
      )}
    </Dialog>
  );
}
