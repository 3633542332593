import React, { useContext, useEffect, useState } from "react";
import { MlGeoJsonLayer, useMap } from "@mapcomponents/react-maplibre";
import * as turf from "@turf/turf";

export default function ResultLayer(props) {
    const mapHook = useMap({ mapId: "map_1" });

    useEffect(() => {
        if (!mapHook.map) {
            return;
        }

        var allBounds = [];
        props.json.forEach((jsonElement) => {
            var geometry = jsonElement;

            var bbox = turf.bbox(geometry);

            if (bbox && bbox.length > 3) {
                var polygon = turf.bboxPolygon(bbox);
                var bufferedPolygon = turf.buffer(polygon, 1.5, {
                    units: "kilometres",
                });
                var bufferedBbox = turf.bbox(bufferedPolygon);

                var bounds = [
                    [bufferedBbox[0], bufferedBbox[1]],
                    [bufferedBbox[2], bufferedBbox[3]],
                ];

                allBounds.push(bounds);
            }
        });

        if (allBounds.length > 0) {
            var totalBounds = allBounds.reduce(
                (acc, currentBounds) => [
                    [
                        Math.min(acc[0][0], currentBounds[0][0]),
                        Math.min(acc[0][1], currentBounds[0][1]),
                    ],
                    [
                        Math.max(acc[1][0], currentBounds[1][0]),
                        Math.max(acc[1][1], currentBounds[1][1]),
                    ],
                ],
                allBounds[0]
            );

            mapHook.map.map.fitBounds(totalBounds, { maxZoom: 16 });
        }
    }, [props.json, mapHook.map]);

    return (
        <>
            {!props.json[0].properties.feldblockidentnummer && (
                <MlGeoJsonLayer
                    geojson={props.json[0]}
                    mapId="map_1"
                    layerId="flik-result"
                    insertBeforeLayer="marker-result"
                    type="line"
                    paint={{
                        "line-width": [
                            "interpolate",
                            ["exponential", 1],
                            ["zoom"],
                            8,
                            4,
                            20,
                            9,
                        ],
                        "line-opacity": [
                            "interpolate",
                            ["exponential", 1],
                            ["zoom"],
                            8,
                            1,
                            20,
                            0.1,
                        ],
                    }}
                />
            )}
        </>
    );
}
