import React, {useState} from "react";
import PropTypes from "prop-types";
import config from "../config";

const SearchContext = React.createContext({});

const SearchStateProvider = SearchContext.Provider;

const SearchContextProvider = ({ children }) => {
  const [searchResult, setSearchResult] = useState([] | undefined);
  const [searchTheme, setSearchTheme] = useState("PLZ");

  const value = {
    searchResult,
    setSearchResult,
    searchTheme,
    setSearchTheme,
    search: {
      plz: {
        suggest: function (searchWord, callback) {
          fetch(config.backendOrigin + "api/plz/suggest/", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ plz: searchWord }),
          })
            .then((res) => {
              if (!res.ok) {
                return false;
              }
              return res.json();
            })
            .then((data) => {
              callback(data);
            });
        },

        search: function (searchWord, callback) {
          fetch(config.backendOrigin + "api/plz/", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ plz: searchWord.slice(0, 5) }),
          })
            .then((res) => {
              if (!res.ok) {
                return false;
              } else {
                return res.json();
              }
            })
            .then((data) => {
              if (typeof callback === "function") {
                callback(data);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        },
      },
      flik: {
        search: function (searchWord, callback) {
          fetch(config.backendOrigin + "api/fid/", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ fid: searchWord }),
          })
            .then((res) => {
              if (!res.ok) {
                return false;
              } else {
                return res.json();
              }
            })
            .then((data) => {
              if (typeof callback === "function") {
                callback(data);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        },
      },
    },
  };

  return <SearchStateProvider value={value}>{children}</SearchStateProvider>;
};

SearchContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SearchContext, SearchContextProvider };
