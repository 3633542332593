import React, { useState } from "react";
import PropTypes from "prop-types";

const DataContext = React.createContext({});

const AppStateProvider = DataContext.Provider;

const DataContextProvider = ({ children }) => {


  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedFromList, setSelectedFromList] = useState();
  const [loading, setLoading] = useState(false);
  const [flikDataHidden, setFlikDataHidden] = useState(false);

  const value = {
    selectedFromList,
    setSelectedFromList,
    selectedFeatures,
    setSelectedFeatures,
    loading,
    setLoading,
    flikDataHidden,
    setFlikDataHidden,
  };

  return <AppStateProvider value={value}>{children}</AppStateProvider>;
};

DataContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { DataContext, DataContextProvider };