import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import theme from "../theme.js";
import { AppBar, Box, Grid, Button, IconButton } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";

import useWindowSize from "../hooks/useWindowSize";
import { SearchBar } from "./SearchBar.jsx";
import { MlScaleReference } from "@mapcomponents/react-maplibre";
import MlCreatePdfButton from "./CreatePdfButton/MlCreatePdfButton.jsx";
import { AppContext } from "../context/AppContext.jsx";
import InfoIcon from "@mui/icons-material/Info";
import MenuIcon from "@mui/icons-material/Menu";
import HideFlikDataButton from "./HideFlikDataButton/HideFlikDataButton.js";

function Header() {
  const size = useWindowSize();
  const appContext = useContext(AppContext);
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        color="default"
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <Toolbar>
          <Grid container>
            <Grid
              item
              key="logo"
              xs={5}
              sm={3}
              md={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
                padding: "4px 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <a href="http://www.redcert.org" target="blank">
                  <img
                    src="assets/logo/REDcert.jpg"
                    alt="RED cert logo"
                    height="35"
                    style={{ marginRight: "4px" }}
                  />
                </a>
                <a href="https://sure-system.org" target="blank">
                  <img src="assets/logo/SURE.jpg" alt="SURE logo" height="35" />
                </a>
              </Box>
            </Grid>
            {size.width > 900 && (
              <Grid
                item
                key="search"
                xs={12}
                sm={8}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <SearchBar />
              </Grid>
            )}
            {size.width <= 1200 && (
              <>
                <Grid
                  item
                  key="scale"
                  xs={7}
                  sm={5}
                  md={3}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "14px",
                    }}
                  >
                    <MlScaleReference />
                  </Box>
                  <HideFlikDataButton></HideFlikDataButton>
                  <MlCreatePdfButton></MlCreatePdfButton>
                  <IconButton
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      appContext.setInfoDialogOpen(true);
                    }}
                  >
                    <InfoIcon />
                  </IconButton>
                  <IconButton
                    variant="contained"
                    onClick={() => {
                      setMenuOpen(!menuOpen);
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </>
            )}
            {size.width <= 1200 && menuOpen && (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Link to="/impressum">
                  <Button>Impressum</Button>
                </Link>
                <Link to="/Datenschutz">
                  <Button>Datenschutz</Button>
                </Link>
              </Grid>
            )}
            {size.width <= 900 && (
              <Grid
                item
                key="search"
                xs={12}
                sm={8}
                md={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <SearchBar />
              </Grid>
            )}
            {size.width > 1200 && (
              <>
                <Grid
                  item
                  key="scale"
                  xs={12}
                  sm={12}
                  md={3}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <MlScaleReference />
                  <Button
                    sx={{
                      marginLeft: "8px",
                    }}
                    onClick={() => {
                      appContext.setInfoDialogOpen(true);
                    }}
                  >
                    <InfoIcon fontSize="large" />
                  </Button>
                  <Link to="/impressum">
                    <Button>Impressum</Button>
                  </Link>
                  <Link to="/Datenschutz">
                    <Button>Datenschutz</Button>
                  </Link>
                </Grid>
              </>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
