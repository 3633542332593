import React, { useContext } from "react";
import { useMap } from "@mapcomponents/react-maplibre";

import createPdf from "./lib/createPdf";
import { DataContext } from "../../context/DataContext";
import PrinterIcon from "@mui/icons-material/Print";
import { IconButton } from "@mui/material";
import * as turf from "@turf/turf";

/**
 * Renders a button that will create a PDF version of the current map view (dimensions adjusted to fit Din A4 Paper).
 */
const MlCreatePdfButton = (props) => {
  const dataContext = useContext(DataContext);
  const mapHook = useMap({
    mapId: props.mapId,
    waitForLayer: props.insertBeforeLayer,
  });

  return (
    <>
      <IconButton
        color="primary"
        variant="contained"
        onClick={() => {
          if (mapHook.map) {
            var textArray = [];
            var center;
            if (dataContext.selectedFeatures[0]) {
              textArray.push(
                "FLIK-Nr.: " +
                  dataContext.selectedFeatures[0].properties
                    .feldblockidentnummer
              );

              textArray.push(
                "Bodennutzungsart: " +
                  dataContext.selectedFeatures[0].properties.bodennutzungstyp
              );
              textArray.push(
                "Jahr: " + dataContext.selectedFeatures[0].properties.jahr
              );
              textArray.push(
                "geeignet: " +
                  (dataContext.selectedFeatures[0].properties.geeignet
                    ? "Ja"
                    : "Nein")
              );
              var area =
                turf.area(dataContext.selectedFeatures[0].geometry) / 10000;
              textArray.push("Fläche: " + area.toFixed(2) + " ha");
              textArray.push(
                "Bundesland: " + dataContext.selectedFeatures[0].properties.land
              );
              center = turf.center(dataContext.selectedFeatures[0]);
              if (center?.geometry?.coordinates) {
                center = center?.geometry?.coordinates;
              } else {
                center = undefined;
              }
              console.log(center);
            }
            createPdf(
              mapHook.map,
              null,
              (loading) => {
                dataContext.setLoading(loading);
              },
              textArray,
              dataContext.selectedFeatures[0],
              center
            );
          }
        }}
        sx={props.sx}
      >
        <PrinterIcon />
      </IconButton>
    </>
  );
};

MlCreatePdfButton.defaultProps = {
  mapId: undefined,
};

export default MlCreatePdfButton;
