import * as React from "react";
import { useContext } from "react";
import { MlWmsLayer } from "@mapcomponents/react-maplibre";

import "maplibre-gl/dist/maplibre-gl.css";
import GeometryVectorTileLayer from "./GeometryVectorTileLayer";
import DataLayer from "./DataLayer";
import LabelLayer from "./LabelLayer";
import MarkerLayers from "./MarkerLayers";
import { AppContext } from "../context/AppContext";

export default function Layers() {
  const appContext = useContext(AppContext);

  return (
    <>
      <MarkerLayers />
      {appContext.markerLayersReady && (
        <>
          <MlWmsLayer
            url="https://sg.geodatenzentrum.de/wms_sen2europe"
            insertBeforeLayer="marker-wms"
            urlParameters={{ layers: "rgb" }}
          />
          <GeometryVectorTileLayer />
          <DataLayer />
          <LabelLayer />
        </>
      )}
    </>
  );
}
