import React, { useEffect, useState } from "react";
import { Typography, Stack, Button } from "@mui/material";
import * as shpwrite from "@mapbox/shp-write";
import config from "../config";

function Downloader(props) {
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (!props.features) return;
        if (props.features.length > 20) {
            setShowError(true);
        } else {
            setShowError("");
        }
    }, [props.features]);

    const getData = (callback) => {
        if (!props.features) return;
        fetch(config.backendOrigin + "api/fid/", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                fid: props.features
                    .map((el) => el.properties.feldblockidentnummer)
                    .join(","),
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    return false;
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                if (typeof callback === "function") {
                    callback(data);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const downloadGeoJSON = () => {
        getData((data) => {
            const geoJSON = {
                type: "FeatureCollection",
                features: data,
            };
            const blob = new Blob([JSON.stringify(geoJSON)], {
                type: "application/json",
            });

            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "flik.geojson";

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    const downloadShapefile = async () => {
        getData((data) => {
            const geoJSON = {
                type: "FeatureCollection",
                features: data,
            };

            const options = {
                folder: "flik",
                filename: "flik",
                outputType: "blob",
                compression: "DEFLATE",
                types: {
                    polygon: "flik",
                },
            };

            shpwrite.download(geoJSON, options);
        });
    };

    return (
        <>
            <Stack
                spacing={2}
                sx={{
                    marginTop: "15px",
                }}
            >
                {showError && (
                    <Typography>
                        Maximal 20 Flächen dürfen für den Download ausgewählt
                        sein.
                    </Typography>
                )}
                <Button
                    disabled={showError ? true : false}
                    variant="contained"
                    onClick={downloadGeoJSON}
                >
                    Download GeoJSON
                </Button>
                <Button
                    disabled={showError ? true : false}
                    variant="contained"
                    onClick={downloadShapefile}
                >
                    Download Shapefile
                </Button>
            </Stack>
        </>
    );
}

export default Downloader;
