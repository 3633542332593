import React from "react";
import ListItem from "@mui/material/ListItem";

function CustomListItem(props) {
    return (
        <ListItem {...props} sx={{ paddingTop: 0, paddingBottom: 0 }}>
            {props.children}
        </ListItem>
    );
}

export default CustomListItem;
