import * as React from "react";
import { AppBar, Box, Typography, IconButton, Divider } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import "simplebar-react/dist/simplebar.min.css";

export default function Sidebar(props) {
  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        style={{
          paddingTop: "74px",
          backgroundColor: "rgba(55,55,55,0.9)",
          zIndex: 1050,
          left: 0,
          height: props.extended ? "100%" : "144px",
          width: props.extended ? "300px" : "105px",
        }}
      >
        <Divider sx={{ border: "2px inset rgb(55,55,55)", borderRadius: 4 }} />
        {props.extended ? (
          <>
            <Typography
              sx={{
                margin: "15px 0 0 15px",
                fontSize: "25px",
              }}
            >
              Flächen Info
            </Typography>
            <Box sx={{ overflow: "auto" }}>{props.children}</Box>
          </>
        ) : (
          <Typography
            sx={{
              margin: "15px 0 0 15px",
              fontSize: "25px",
            }}
          >
            Info
          </Typography>
        )}
        <IconButton
          style={{
            position: "absolute",
            marginTop: "15px",
            right: "0",
          }}
          edge="start"
          color="inherit"
          onClick={(ev) => {
            props.setHeaderExtended(!props.extended);
          }}
        >
          {props.extended ? (
            <ArrowBackIosNewIcon style={{ width: "30px" }} />
          ) : (
            <ArrowForwardIosIcon style={{ width: "30px" }} />
          )}
        </IconButton>
      </AppBar>
    </>
  );
}
