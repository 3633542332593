import React from "react";
import { Typography, Grid } from "@mui/material";

export default function FeaturePreview(props) {
  return (
    <>
      {props.feature?.properties && (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6"> FLIK-Nummer </Typography>
            <Typography variant="body1">
              {props.feature.properties.feldblockidentnummer}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6"> Bodennutzungsart </Typography>
            <Typography variant="body1">
              {props.feature.properties.bodennutzungstyp}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
}

const keyTitels = ["Flächengröße", "FLIK-Nummer"];
