import { MlLayer } from "@mapcomponents/react-maplibre";

const markerLayers = [ "result", "labels", "data", "bg-geometry", "wms"];

const MarkerLayers = () => {
  return (
    <>
      {markerLayers.map((layer, idx) => (
        <MlLayer
          layerId={"marker-" + layer}
          options={{
            display: "none",
          }}
          key={layer}
          {...(idx > 0
            ? {
                insertBeforeLayer: "marker-" + markerLayers[idx - 1],
              }
            : {
                insertBeforeLayer: "background",
              })}
        />
      ))}
    </>
  );
};

export default MarkerLayers;
