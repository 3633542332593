import React from "react";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Routes, Route, Link } from "react-router-dom";

import Pagedialog from "../components/Pagedialog";
import Start from "../pages/Start";
import Imprint from "../pages/Imprint";
import Datasecurity from "../pages/Datasecurity";

export default function Pages() {
  const appContext = useContext(AppContext);

  return (
    <>
      <Pagedialog
        title="FLIK-Suche"
        open={appContext.infoDialogOpen}
        handleClose={() => {
          appContext.setInfoDialogOpen(false);
        }}
      >
        <Start />
      </Pagedialog>
      <Routes>
        <Route
          path="/impressum"
          element={
            <Pagedialog
              title="Impressum"
              open={!appContext.imprintDialogOpen}
              handleClose={() => {
                appContext.setImprintDialogOpen(false);
              }}
            >
              <Imprint />
            </Pagedialog>
          }
        />
        <Route
          path="/datenschutz"
          element={
            <Pagedialog
              title="Datenschutz"
              open={!appContext.datasecurityDialogOpen}
              handleClose={() => {
                appContext.setDatasecurityDialogOpen(false);
              }}
            >
              <Datasecurity />
            </Pagedialog>
          }
        />
      </Routes>
    </>
  );
}
