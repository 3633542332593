import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMap } from "@mapcomponents/react-maplibre";

const AppContext = React.createContext({});

const AppStateProvider = AppContext.Provider;

const AppContextProvider = ({ children }) => {
  const [markerLayersReady, setMarkerLayersReady] = useState(false);
  const mapHook = useMap({ mapId: "map_1", insertBeforeLayer: "marker-wms" });
  const [infoDialogOpen, setInfoDialogOpen] = useState(true);
  const [imprintDialogOpen, setImprintDialogOpen] = useState(false);
  const [securityDialogOpen, setSecurityDialogOpen] = useState(false);

  useEffect(() => {
    if (!mapHook.map) return;
    setMarkerLayersReady(true);
  }, [mapHook.map]);

  const value = {
    markerLayersReady,
    infoDialogOpen,
    setInfoDialogOpen,
    imprintDialogOpen,
    setImprintDialogOpen,
    securityDialogOpen,
    setSecurityDialogOpen,
  };

  return <AppStateProvider value={value}>{children}</AppStateProvider>;
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AppContext, AppContextProvider };