import * as React from "react";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "./ListItem";
import FeaturePreview from "./FeaurePreview";

export default function InfoPopper(props) {
  const [open, setOpen] = React.useState(false);
  //const feature = props.feature;

  useEffect(() => {
    if (props.hovered) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  });

  return (
    <Popper open={open} placement={"right"} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            sx={{
              marginBottom: "30px",
              height: "60px",
              boxShadow: 1,
              borderRadius: 2,
              p: 2,
              minWidth: 375,
            }}
          >
            <List disablePadding={true}>
              <ListItem>
                {props.feature && <FeaturePreview feature={props.feature} />}
              </ListItem>
            </List>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}
