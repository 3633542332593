import React, { useCallback, useMemo } from "react";
import { useContext, useState } from "react";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Autocomplete,
  Paper,
  IconButton,
  Box,
  TextField,
  Grid,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import bundeslaender from "../assets/bundeslaender.json";
import ResultLayer from "./ResultLayer";
import useWindowSize from "../hooks/useWindowSize";
import { SearchContext } from "../context/SearchContext";
import { useMap } from "@mapcomponents/react-maplibre";

const themes = ["FLIK-Nummer", "PLZ", "Bundesland"];
const laender = [
  "Bayern",
  "Brandenburg",
  "Hessen",
  "Niedersachsen",
  "Nordrhein-Westfalen",
  "Mecklenburg-Vorpommern",
  "Rheinland-Pfalz",
  "Sachsen",
  "Sachsen-Anhalt",
  "Thüringen",
];

export function SearchBar() {
  const mapHook = useMap({ mapId: "map_1" });
  const searchContext = useContext(SearchContext);
  const [searchWord, setSearchWord] = useState("");
  const [suggestion, setSuggestion] = useState([]);
  const size = useWindowSize();

  // get PLZ Suggestions
  const getSuggestions = useCallback(() => {
    if (searchContext.searchTheme === "PLZ" && searchWord?.length > 1) {
      searchContext.search.plz.suggest(searchWord, (data) => {
        var plzValues = [];
        data = data.filter((v) => {
          var exp = plzValues.indexOf(v[0]) === -1;
          plzValues.push(v[0]);
          return exp;
        });
        setSuggestion(
          data.map((item) => ({
            label: item[0] + ", " + item[1],
            value: item[0],
          }))
        );
      });
    }
  }, [searchContext.searchTheme, searchWord, searchContext.search]);

  //Event handlers

  const handleChangeSelectedOption = (event, newValue) => {
    console.log("handleChangeSelectedOption");
    console.log(event?.nativeEvent?.pointerType);
    if (["mouse", "touch"].indexOf(event?.nativeEvent?.pointerType) !== -1) {
      console.log(newValue);
      console.log(event);
      if (newValue.indexOf(", ") !== -1) {
        newValue = newValue.split(",")[0];
      }
      search(newValue);
    } else {
      setSearchWord(newValue);
    }
  };

  const handleInputChange = (event) => {
    setSearchWord(event.target.value);
  };

  const handleEnterkey = (event) => {
    if (event.keyCode == 13) {
      search(event.target.value);
    }
  };

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      search(searchWord);
    },
    [searchWord]
  );

  // get Query Results
  const search = useCallback(
    (searchWord) => {
      if (searchContext.searchTheme === "Bundesland") {
        let _results = bundeslaender.features.filter((item) => {
          return item.properties.name === searchWord;
        });
        if (typeof _results[0] !== "undefined") {
          searchContext.setSearchResult(_results);
        }
      }
      var _searchCallback = (data) => {
        if (data) {
          searchContext.setSearchResult(data);
          setSearchWord("");
          setSuggestion([]);
        }
      };

      if (searchContext.searchTheme === "FLIK-Nummer") {
        searchContext.search.flik.search(searchWord, _searchCallback);
      }
      if (searchContext.searchTheme === "PLZ") {
        searchContext.search.plz.search(searchWord, _searchCallback);
      }
    },
    [mapHook.map, searchContext.search, searchContext.searchTheme]
  );

  const searchThemeValueLabelMap = useMemo(() => {
    if (size.width > 600) {
      return {
        "FLIK-Nummer": "FLIK-Nummer",
        PLZ: "PLZ",
        Bundesland: "Bundesland",
      };
    } else {
      return {
        "FLIK-Nummer": "FLIK-Nr.",
        PLZ: "PLZ",
        Bundesland: "Bundesl.",
      };
    }
  }, [size]);

  return (
    <>
      <form
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Paper
          style={{
            margin: "5px 0",
            maxWidth: "640px",
            width: "100%",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Grid container style={{ alignItems: "center" }}>
            {size.width > 600 && <Grid item xs={1}></Grid>}
            <Grid item xs={4} sx={{ paddingLeft: "13px" }}>
              <FormControl
                sx={{ m: 1, width: size.width > 600 ? "140px" : "88px" }}
              >
                <InputLabel
                  variant="standard"
                  id="search-type-select-helper-label"
                >
                  Suchtyp
                </InputLabel>
                <Select
                  options={themes}
                  variant="standard"
                  value={searchContext.searchTheme}
                  label="Suchtyp"
                  labelId="search-type-select-helper-label"
                  onChange={(e, v) => {
                    searchContext.setSearchTheme(e.target.value);
                    setSearchWord("");
                  }}
                >
                  {themes.map((el) => (
                    <MenuItem key={el} value={el}>
                      {searchThemeValueLabelMap[el]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {searchContext.searchTheme === "" && <Grid item xs={6}></Grid>}
            {searchContext.searchTheme === "FLIK-Nummer" && (
              <Grid item xs={6}>
                <TextField
                  variant="standard"
                  sx={{ marginTop: "16px", width: "100%" }}
                  id="inputFLIK"
                  placeholder="Geben Sie eine Nummer ein."
                  value={searchWord}
                  onChange={handleInputChange}
                />
              </Grid>
            )}

            {searchContext.searchTheme === "PLZ" && (
              <>
                <Grid item xs={6}>
                  <Autocomplete
                    options={suggestion}
                    value={searchWord}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        label="Suchbegriff"
                        {...params}
                      />
                    )}
                    onInputChange={handleChangeSelectedOption}
                    //onSelect={handleDirectSearch}
                    onKeyDown={handleEnterkey}
                    isOptionEqualToValue={(option, value) =>
                      option?.value === value
                    }
                    onSelect={(e) => {
                      getSuggestions();
                    }}
                    onChange={handleEnterkey}
                  />
                </Grid>
              </>
            )}
            {searchContext.searchTheme === "Bundesland" && (
              <>
                <Grid item xs={6}>
                  <Autocomplete
                    options={laender}
                    value={searchWord}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        label="Suchbegriff"
                        {...params}
                        onKeyDown={handleEnterkey}
                      />
                    )}
                    onInputChange={handleChangeSelectedOption}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={1} display="block">
              <IconButton
                aria-label="search"
                style={{ color: "black" }}
                onClick={handleSubmit}
                type="submit"
              >
                <SearchIcon style={{ color: "black" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      </form>
      {searchContext.searchResult?.[0] && (
        <ResultLayer json={searchContext.searchResult} />
      )}
    </>
  );
}
