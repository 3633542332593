import React from "react";
import { MlVectorTileLayer } from "@mapcomponents/react-maplibre";

export default function GeometryVectorTileLayer() {
  return (
    <MlVectorTileLayer
      mapId="map_1"
      url="https://wms.wheregroup.com/tileserver/tile/tileserver.php?/europe-0-14/index.json?/europe-0-14/{z}/{x}/{y}.pbf"
      //layerId="buildings"
      insertBeforeLayer="marker-bg-geometry"
      layers={{
        building: {
          id: "building",
          type: "fill",
          metadata: {
            "mapbox:group": "1444849364238.8171",
          },

          "source-layer": "building",
          minzoom: 8,
          maxzoom: 24,
          paint: {
            "fill-outline-color": "#dfdbd7",
            "fill-color": "#f2eae2",
            "fill-opacity": [
              "interpolate",
              ["exponential", 1],
              ["zoom"],
              8,
              0.1,
              16,
              1,
            ],
          },
        },
        building_top: {
          id: "building-top",
          type: "fill",
          metadata: {
            "mapbox:group": "1444849364238.8171",
          },

          "source-layer": "building",
          layout: {
            visibility: "visible",
          },
          minzoom: 8,
          maxzoom: 24,
          paint: {
            "fill-outline-color": "#dfdbd7",
            "fill-color": "#f2eae2",
            "fill-opacity": [
              "interpolate",
              ["linear", 1],
              ["zoom"],
              8,
              0.1,
              16,
              1,
            ],
          },
        },
        highway_area: {
          id: "highway-area",
          type: "fill",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          filter: ["==", "$type", "Polygon"],
          layout: {
            visibility: "visible",
          },
          paint: {
            "fill-color": "hsla(0, 0%, 89%, 0.56)",
            "fill-outline-color": "#cfcdca",
            "fill-opacity": 0.9,
            "fill-antialias": false,
          },
        },
        higways_link_case: {
          id: "highway-link-casing",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          minzoom: 13,
          filter: [
            "all",

            [
              "in",
              "class",
              "primary_link",
              "secondary_link",
              "tertiary_link",
              "trunk_link",
            ],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
            visibility: "visible",
          },
          paint: {
            "line-color": "#e9ac77",
            "line-opacity": 1,
            "line-width": {
              base: 1.2,
              stops: [
                [12, 1],
                [13, 3],
                [14, 4],
                [20, 15],
              ],
            },
          },
        },
        higway_minor: {
          id: "highway-minor-casing",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            ["all", ["in", "class", "minor", "service", "track"]],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#cfcdca",
            "line-opacity": {
              stops: [
                [12, 0],
                [12.5, 1],
              ],
            },
            "line-width": {
              base: 1.2,
              stops: [
                [12, 0.5],
                [13, 1],
                [14, 4],
                [20, 15],
              ],
            },
          },
        },
        higway_sec_case: {
          id: "highway-secondary-tertiary-casing",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          filter: ["all", ["in", "class", "secondary", "tertiary"]],
          layout: {
            "line-cap": "round",
            "line-join": "round",
            visibility: "visible",
          },
          paint: {
            "line-color": "#e9ac77",
            "line-opacity": 1,
            "line-width": {
              base: 1.2,
              stops: [
                [8, 1.5],
                [20, 17],
              ],
            },
          },
        },
        higway_prim_case: {
          id: "highway-primary-casing",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          minzoom: 5,
          filter: ["all", ["in", "class", "primary"]],
          layout: {
            "line-cap": "round",
            "line-join": "round",
            visibility: "visible",
          },
          paint: {
            "line-color": "#e9ac77",
            "line-opacity": {
              stops: [
                [7, 0],
                [8, 1],
              ],
            },
            "line-width": {
              base: 1.2,
              stops: [
                [7, 0],
                [8, 0.6],
                [9, 1.5],
                [20, 22],
              ],
            },
          },
        },
        higway_motorway: {
          id: "highway-motorway-casing",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          minzoom: 4,
          filter: [
            "all",
            ["!in", "brunnel", "bridge", "tunnel"],
            ["==", "class", "motorway"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
            visibility: "visible",
          },
          paint: {
            "line-color": "#e9ac77",
            "line-width": {
              base: 1.2,
              stops: [
                [4, 0],
                [5, 0.4],
                [6, 0.6],
                [7, 1.5],
                [20, 22],
              ],
            },
            "line-opacity": {
              stops: [
                [4, 0],
                [5, 1],
              ],
            },
          },
        },
        higway_path: {
          id: "highway-path",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            [
              "all",
              ["!in", "brunnel", "bridge", "tunnel"],
              ["==", "class", "path"],
            ],
          ],
          paint: {
            "line-color": "#cba",
            "line-dasharray": [1.5, 0.75],
            "line-width": {
              base: 1.2,
              stops: [
                [15, 1.2],
                [20, 4],
              ],
            },
          },
        },
        higway_motorway_link: {
          id: "highway-motorway-link",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          minzoom: 12,
          filter: [
            "all",
            ["!in", "brunnel", "bridge", "tunnel"],
            ["==", "class", "motorway_link"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#fc8",
            "line-width": {
              base: 1.2,
              stops: [
                [12.5, 0],
                [13, 1.5],
                [14, 2.5],
                [20, 11.5],
              ],
            },
          },
        },
        higway_link: {
          id: "highway-link",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          minzoom: 13,
          filter: [
            "all",
            ["!in", "brunnel", "bridge", "tunnel"],
            [
              "in",
              "class",
              "primary_link",
              "secondary_link",
              "tertiary_link",
              "trunk_link",
            ],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
            visibility: "visible",
          },
          paint: {
            "line-color": "#fea",
            "line-width": {
              base: 1.2,
              stops: [
                [12.5, 0],
                [13, 1.5],
                [14, 2.5],
                [20, 11.5],
              ],
            },
          },
        },
        higway_sec: {
          id: "highway-secondary-tertiary",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          filter: [
            "all",
            ["!in", "brunnel", "bridge", "tunnel"],
            ["in", "class", "secondary", "tertiary"],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
            visibility: "visible",
          },
          paint: {
            "line-color": "#fea",
            "line-width": {
              base: 1.2,
              stops: [
                [6.5, 0],
                [8, 0.5],
                [14, 9],
                [20, 13],
              ],
            },
          },
        },
        higway_prim: {
          id: "highway-primary",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            [
              "all",
              ["!in", "brunnel", "bridge", "tunnel"],
              ["in", "class", "primary"],
            ],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
            visibility: "visible",
          },
          paint: {
            "line-color": "#fea",
            "line-width": [
              "interpolate",
              ["exponential", 1],
              ["zoom"],
              8,
              0.5,
              22,
              14,
            ],
          },
        },
        highway_tunk: {
          id: "highway-trunk",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            [
              "all",
              ["!in", "brunnel", "bridge", "tunnel"],
              ["in", "class", "trunk"],
            ],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
            visibility: "visible",
          },
          paint: {
            "line-color": "#fea",
            "line-width": {
              base: 1.2,
              stops: [
                [6.5, 0],
                [7, 0.5],
                [20, 18],
              ],
            },
          },
        },
        higway_motorway_line: {
          id: "highway-motorway",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          minzoom: 5,
          filter: [
            "all",
            ["==", "$type", "LineString"],
            [
              "all",
              ["!in", "brunnel", "bridge", "tunnel"],
              ["==", "class", "motorway"],
            ],
          ],
          layout: {
            "line-cap": "round",
            "line-join": "round",
            visibility: "visible",
          },
          paint: {
            "line-color": "#fc8",
            "line-width": {
              base: 1.2,
              stops: [
                [6.5, 0],
                [7, 0.5],
                [20, 18],
              ],
            },
          },
        },
        railway_prim: {
          id: "railway-service",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            ["all", ["==", "class", "rail"], ["has", "service"]],
          ],
          paint: {
            "line-color": "hsla(0, 0%, 73%, 0.77)",
            "line-width": {
              base: 1.4,
              stops: [
                [14, 0.4],
                [20, 1],
              ],
            },
          },
        },
        railway: {
          id: "railway",
          type: "line",
          metadata: {
            "mapbox:group": "1444849345966.4436",
          },

          "source-layer": "transportation",
          filter: [
            "all",
            ["==", "$type", "LineString"],
            [
              "all",
              ["!has", "service"],
              ["!in", "brunnel", "bridge", "tunnel"],
              ["==", "class", "rail"],
            ],
          ],
          paint: {
            "line-color": "#bbb",
            "line-width": {
              base: 1.4,
              stops: [
                [14, 0.4],
                [15, 0.75],
                [20, 2],
              ],
            },
          },
        },
      }}
      sourceOptions={{ minzoom: 8, maxzoom: 14 }}
    />
  );
}
