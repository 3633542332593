import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MapComponentsProvider } from "@mapcomponents/react-maplibre";
import { AppContextProvider } from "./context/AppContext";
import { DataContextProvider } from "./context/DataContext";

import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material";
import { SearchContextProvider } from "./context/SearchContext";

import { BrowserRouter } from "react-router-dom";

const defaultTheme = createTheme({});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <MapComponentsProvider>
          <DataContextProvider>
            <AppContextProvider>
              <SearchContextProvider>
                <App />
              </SearchContextProvider>
            </AppContextProvider>
          </DataContextProvider>
        </MapComponentsProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
