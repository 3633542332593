import React from "react";

export default function Start() {
  return (
    <>
      Herzlich willkommen auf unserer „FLIK-Suche“, der Recherche-Plattform zum
      Flächenstatus von Anbauflächen für Biomasse in Deutschland im Rahmen der
      Nachhaltigkeitszertifizierung gemäß Richtlinie (EU) 2018/2001. Die
      Richtlinie legt fest, dass zulässige Anbauflächen für nachhaltig erzeugte
      Biomasse zum Referenzzeitpunkt 01. Januar 2008 den Status „Ackerfläche“
      nachweisen müssen. Für Flächen, die zu diesem Zeitpunkt einen anderen
      Status aufweisen, z.B. Grünland, gelten zusätzliche Bestimmungen, wenn
      diese zu einem späteren Zeitpunkt als Anbaufläche genutzt werden sollen.
      <br />
      <br />
      Mit Hilfe dieser Recherche-Plattform können Sie einfach und unkompliziert
      über die bundesweit einheitliche FLIK-Nummer einer Fläche den historischen
      Status zum Referenzzeitpunkt ermitteln und die Fläche sichtbar machen, so
      dass auch ein Abgleich mit heutigen, ggf. veränderten Flächenzuschnitten
      möglich ist. Alternativ ist eine Karten-basierte Suche möglich, wenn die
      FLIK-Nummer nicht bekannt ist. Der Nachweis des Flächenstatus wird im
      Rahmen der Stichprobenkontrollen von Erzeugerbetrieben zur
      Nachhaltigkeitszertifizierung regelmäßig durch Auditoren überprüft.
      <br />
      <br />
      Die hierzu notwendigen Daten wurden auf Initiative und nachdrückliche
      Bitte des Deutschen Bauernverbands von den zuständigen Ämtern und
      Dienststellen von folgenden Bundesländern zur Verfügung gestellt:
      <br />
      <br />
      Bayern, Brandenburg, Hessen, Mecklenburg-Vorpommern, Nordhein-Westfalen,
      Niedersachsen, Rheinland-Pfalz, Sachsen-Anhalt, Sachsen, Thüringen
      <br />
      <br />
      Aufgrund der amtlichen Datenlage sind bei zahlreichen Feldblöcken (mit
      violetter Umrandung) für darin enthaltene Teilflächen unterschiedliche
      Statusinformationen hinterlegt. Hier müssen ggf. zusätzliche Nachweise für
      eine zweifelsfreie Statusinformation einzelner Teilflächen (Schläge) zum
      Referenzzeitpunkt vorgelegt werden.
      <br />
      <br />
      Wir bemühen uns derzeit, diese Daten auch von den verbleibenden
      Bundesländern zu erhalten, um die Plattform entsprechend erweitern zu
      können. Bei Fragen, Anregungen oder Problemen nutzen Sie bitte folgende
      E-Mailadresse{" "}
      <a href="mailto:&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#102;&#108;&#105;&#107;&#045;&#115;&#117;&#099;&#104;&#101;&#046;&#100;&#101;">
        &#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#102;&#108;&#105;&#107;&#045;&#115;&#117;&#099;&#104;&#101;&#046;&#100;&#101;
      </a>
      .
      <br />
      <br />
      Viel Erfolg!
    </>
  );
}
